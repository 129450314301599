import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppEuropamivagyunk = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Óvjuk meg a jövőnket! Szavazz az EU választásokon, az alábbiak szerint."
      description="Klikkelj a linkre és küldj üzenetet barátaidnak WhatsAppon- hallasd a hangod! ➡️"
      shareImage="hungarian"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hello! Nagy-Britanniában élő Magyar állampolgár vagy? EU állampolgárként szavazahatunk a Nagy Britanniai EU választásokon!

💥Küldd ezt az üzenetet Nagy-Britanniában élő Magyar barátaidnak.💥
Kattints ide: https://saveourfuture.uk/europamivagyunk

Regisztrálj a Május 7.-ig az EUs szavazásra. Kövesd az egyszerű lépéseket al alábbiak szerint:
https://saveourfuture.uk/simple Most tedd meg!   FONTOS!

FONTOS!!!
💥Küldd el ezt az üzenetet Magyar EK -beli barátaidnak, ismerőseidenk - ez a mi otthonunk is.💥
Kattints ide: https://saveourfuture.uk/europamivagyunk

🗣 EZ ALKALOMMAL A MI SZAVAZATUNK SZÁMÍT! 🗣`}
    />
    <Interstitial>
      <p>Válasszon öt legjobb barátot vagy csoportot a Whatsapp üzenet elküldéséhez</p>
      <p>Üzenet létrehozása most</p>
      <p>A következő lépésben személyre szabhatja az üzenetet ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppEuropamivagyunk.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppEuropamivagyunk;

export const query = graphql`
  query EuropamivagyunkQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
